/*
Display product features in 2 cols
 */
.h6 {
  font-weight: 600;
}

.product-features {
  font-weight: $display1-weight;
  $font-size: $display1-size;

  #block1, #block2 {
    font-weight: normal;
  }

}


.product-prices{
  color:$primary;
  font-size:$h2-font-size !important;
}