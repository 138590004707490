li [data-depth="0"] > a {
  margin-left: 0px;
  color: $category-color-first;
  font-weight: $category-font-weight-first;
  &:hover {
    color: $category-hover-first;
  }
}

li[data-depth="0"]{
  padding-bottom: 5px;
  border-bottom: 1px solid $category-border-first;
}


.category-sub-menu {
  margin-top: 6px;
  li {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    .material-icons {
      float: right;
      font-size: 1rem;
      position: relative;
      cursor: pointer;
    }
  }
  li [data-depth="1"] {
    padding-top: 7px;
    margin-left: 10px !important;
  }
  li [data-depth="1"] > a {
    margin-left: 0px;
    margin-top: 8px;

    color: $category-color-second;
    font-weight: $category-font-weight-second;
    &:hover {
      color: $category-hover-second;
    }
  }

  li [data-depth="2"] > a {
    margin-left: 10px;
    margin-top: 8px;
    display: block;
    color: $category-color-third;
    font-weight: $category-font-weight-third;
    &:hover{
      color: $category-hover-third;
    }
  }

  li [data-depth="3"] > a {
    margin-left: 20px;
    margin-top: 8px;
    display: block;
    color: $category-color-forth;
    font-weight: $category-font-weight-forth;
    &:hover {
      color: $category-hover-forth;
    }

  }

  li [data-depth="1"] {
    margin-left: 20px;

  }

  .category-active {
    font-weight: 600!important;
  }
}