/* PRODUCT MINIATURE */
$pm-bg-color: null;
$pm-border-width: null;
$pm-border-color: null;
/* product title */
$pm-name-color: $gray-700;
$pm-name-hover-color: $primary;
$pm-name-font-size: $h6-font-size;
$pm-name-font-weight: 600;
$pm-name-font-family: null;
$pm-name-font-size: null;
$pm-name-hover-border-width: 1px;
$pm-name-hover-border-color: transparent;

$pm-highlight-bg-color: #fff;
$pm-highlight-translateY: 0;