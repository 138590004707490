.l-footer {
  color: $footer-color;


  .cms-page-link,
  .account-list a {
    color: $footer-color;

    &:hover {
      color: $footer-hover-color;
    }
  }
}

.footer-container {
  margin-top: $footer-container-margin-top;
  padding-top:$footer-container-padding-top;
  border-top: $footer-border;
}

.footer__title {
  color: $footer-title-color;

  &:visited, &:hover {
    color: $footer-title-color;
  }

  text-transform: uppercase;
  display: block;
  font-weight: 700;
  margin-bottom: $spacer/2;

  &--mobile {
    display: none;
    margin-bottom: 0;
    padding: $spacer/2 0;
    border-bottom: 1px solid $footer-border-color;


    &:after {
      content: "\e313";
      font-family: 'Material Icons';
      font-feature-settings: 'liga' 1;
      position: absolute;
      right: $grid-gutter-width/2;
      transition: .3s transform ease-in-out;
      font-size: $font-size-base *1.5;
    }

    &[data-toggle="collapse"][aria-expanded="true"]:after {
      transform: rotate(180deg);
    }
  }
}

@include media-breakpoint-mobile {
  .footer__title {
    &--desktop {

      display: none;
    }

    &--mobile {
      display: block;
    }
  }
}

.footer__copyright {
  text-align: center;
  font-size: $font-size-sm;
}

.header__nav {
  align-items: center;
}

.header-nav__nav2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-mobile__user-cart {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

§
.header__top {
  display: flex;
  justify-content: space-between;
  position: static;
}
