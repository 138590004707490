#mp-date-start label {
  width:120px !important;
}
#mp-date-end label {
  width:120px !important;
}
#btnAvailability {
  margin:0px !important;
}
#btnAvailability2 {
  margin:0px !important;
}
