@import "variables/layout";
@import "variables/product-miniature";
@import "variables/searchbar";

//colors
$bg-dark: #dededd;
$tertiary: #F40101;


/*
header
 */

$header-color: #508469; //Header Schriftfarbe
$header-hover: $primary;

$header-nav-dropdown-color: $gray-700; //Sprachauswahl
$header-nav-dropdown-hover-color: $primary;
$header-nav-dropdown-background-color: $body-bg;

$header-bg: #ffffff; //Header Hintergrundfarbe
$header-box-shadow: 0 2px 6px 0 rgb(86, 86, 86); //Schatten unter Header
$header-nav-color: null;
$header-nav-padding-y: null;
$header-nav-border-color: $border-color;
$header-top-border-color: $header-nav-border-color;

/*
main
 */
$wrapper-bg: #ffffff;
$spacer-y: $spacer*2;

/*
Footer
 */
//$newsletter-background: #737373;
//$newsletter-text-color: $gray-400;

//$footer-background-color: #3f3f3f;
$footer-background-color: #356350;
$footer-border: solid 1px $gray-600;
$footer-title-color: $gray-100;
$footer-text-color: $gray-400;
$footer-hover-color: $white;


$footer-bg: $footer-background-color;
$footer-title-color: $footer-title-color;
$footer-color: $footer-text-color;
$footer-border-color: $footer-border;
$footer-container-margin-top: $spacer;
$footer-container-padding-top: $spacer;

/*
Categorytree
 */
$category-background-first: $gray-300;
$category-color-first: $gray-600;
$category-font-weight-first: 600;
$category-hover-first: $gray-900;
$category-border-first: $gray-300;

$category-background-second: $gray-400;
$category-color-second: $gray-600;
$category-font-weight-second: 500;
$category-hover-second: #14384b;
$category-border-second: $gray-300;

$category-background-third: $gray-400;
$category-color-third: $primary;
$category-font-weight-third: 500;
$category-hover-third: #14384b;
$category-border-third: $gray-200;

$category-background-forth: $gray-400;
$category-color-forth: $primary;
$category-font-weight-forth: 500;
$category-hover-forth: #14384b;
$category-border-forth: $gray-200;

/*
Mainmenu
 */

//main menu
$menu-topitem-font-size: $font-size-base;
$menu-topitem-font-weight: 600;
$menu-topitem-color: $gray-800;
$menu-topitem-text-transform: uppercase;
$menu-topitem-padding: $spacer/2;
$menu-topitem-margin-left: $spacer*2;

$menu-sub-top: 60px;

$menu-1-color: $gray-700;
$menu-2-color: $gray-600;
$menu-3-color: $gray-600;
$menu-4-color: $gray-600;
$menu-5-color: $gray-600;

$menu-1-hover-color: $gray-800;
$menu-2-hover-color: $gray-800;
$menu-3-hover-color: $gray-800;
$menu-4-hover-color: $gray-800;
$menu-5-hover-color: $gray-800;

$menu-1-margin-left: $spacer;
$menu-2-margin-left: $spacer*1.2;
$menu-3-margin-left: $spacer*1.6;
$menu-4-margin-left: $spacer*1.8;
$menu-5-margin-left: $spacer*2;

//main menu mobile
$menu-item-padding: $spacer/2;
$menu-item-border-color: $border-color;
$menu-sub-mobile-bg-color: $wrapper-bg;


$mainmenu-top-menu-li-float: left;


// Navs

$nav-tabs-border-width: 2px;
$nav-tabs-border-color: $gray-300 !default;
$nav-tabs-link-hover-border-color: transparent transparent $nav-tabs-border-color;
$nav-tabs-link-active-color: $primary;
$nav-tabs-link-active-border-color: transparent transparent $primary;
// Pagination

$pagination-border-color: $gray-100;
$pagination-disabled-border-color: $gray-100;
$pagination-hover-border-color: $gray-100;



//BS touchspin
$bs-touchspin-boxshadow: 2px 2px 4px 0 rgba(0, 0, 0, .2);

//cart
$promo-code-bg: darken($body-bg, 10%);

//offcanvas
$zindex-offcanvas: $zindex-modal;
$offcanvas-width: 300px;

//product slider
$product-thumb-margin: 5px;
$product-thumb-width: 100px;
$product-thumb-height: 100px;
$product-to-show: 3; //if you edit this, you have to add centerPadding ppy in data-slick with same value
$product-thumb-wrap-width: $product-to-show * ($product-thumb-width + 2 * $product-thumb-margin) - 2 * $product-thumb-margin;
$product-thumb-wrap-width-2: 2 * ($product-thumb-width + 2 * $product-thumb-margin) - 2 * $product-thumb-margin;
$product-thumb-wrap-height: $product-thumb-height + $product-thumb-margin * 2;

//prices
$product-price-current-color: null;
$product-price-current-font-size: null;
$product-price-current-font-weight: 700;
$product-price-current-font-family: null;

$product-price-regular-color: #7a7a7a;
$product-price-regular-font-size: $font-size-base * .875;
$product-price-regular-font-weight: 700;
$product-price-regular-font-family: null;


$product-price-discount-color: $tertiary;
$product-price-discount-font-size: null;
$product-price-discount-font-weight: 700;
$product-price-discount-font-family: null;


//product color box
$colorbox-border-color: #232323;

//product add to cart spinner
$spinner-width-addtocart: $btn-font-size-lg;
$spinner-height-addtocart: $btn-font-size-lg;
$spinner-borderwidth-addtocart: 2px;


//social share
$social-share-size: 2.5 * $font-size-base;
$social-share-bg-color: $gray-300;



//heading
$h1-text-transform: none;

//footer
$footer-margin-top: $spacer;
$footer-padding-top: $spacer;

//util
//$card-box-shadow : $box-shadow !default;
$card-box-shadow: null !default;

//product section

$section-title-font-family: null;
$section-title-color: null;
$section-title-size: null;
$section-title-font-weight: null;
$section-title-text-align: center;
$section-title-text-transform: uppercase;
$section-title-margin-bottom: $spacer;

//blockcart
$blockcart-color: $white;
$blockcart-background-color: $primary;
$blockcart-inactive-color: $primary;
$blockcart-inactive-background-color: $white;
$blockcart-padding: 5px;

//block left column
$block-border-color: null;




